import {
  Button,
  Center,
  Flex,
} from '@chakra-ui/react'
import {
  Calendar,
  Case,
  Cup,
  FilledBook,
  Allies,
  Polls,
  Games,
  Medal,
  NewsIcon,
  Quests
} from '@olagg/ui-kit'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useAuthStore, useOnboardingStore } from '@olagg/store'

import MyAccountNav from './MyAccountNav'
import NavMenu from './Menu'
import { ROUTES } from '../../routes'
import { useTranslation } from 'react-i18next'
import Notifications from '../Notifications'

const Nav = () => {
  const { loggedIn } = useAuthStore();
  const { openOnboarding } = useOnboardingStore()

  const navigate = useNavigate()
  const { lang } = useParams();
  const { i18n, t } = useTranslation()

  const changeLang = (newLang) => {
    const currentPath = window.location.pathname.split('/').slice(2).join('/');
    i18n.changeLanguage(newLang);
    const searchParams = location.search;
    localStorage.setItem('i18n_lang', newLang);
    navigate(`/${newLang}/${currentPath}${searchParams}`, { replace: true });
  }

  return (
    <Center>
      <Flex
        h="full"
        px='20px'
        justify="center"
        align="end"
        pb={{ base: 3, md: 0 }}
      >
        <NavMenu
          buttonText={t('navbar.games')}
          menuOptions={[
            { text: t('navbar.gamesMenu.tournaments'), to: ROUTES.TORNEOS, icon: <Cup width='16px' height='16px' fillOpacity='1' /> },
            { text: t('navbar.gamesMenu.quests'), to: ROUTES.QUESTS, icon: <Quests width='16px' height='16px' /> },
            { text: t('navbar.gamesMenu.gameCatalog'), to: ROUTES.GAMES, icon: <Games width='16px' height='16px' /> }
          ]}
        />
        <NavMenu
          buttonText={t('navbar.community')}
          menuOptions={[
            { text: t('navbar.communityMenu.ranking'), to: ROUTES.RANKING, icon: <Medal width='16px' height='16px' /> },
            { text: t('navbar.communityMenu.polls'), to: ROUTES.POLLS, icon: <Polls height='19px' />, new: true },
            { text: t('navbar.communityMenu.opportunities'), to: ROUTES.OPPORTUNITIES, icon: <Case width='16px' height='16px' /> },
            { text: t('navbar.communityMenu.courses'), to: '/landing/aprende', icon: <FilledBook width='16px' height='16px' /> },
            { text: t('navbar.communityMenu.alliesWithOla'), to: ROUTES.B2B, icon: <Allies width='16px' height='16px' /> }
          ]}
        />
        <NavMenu
          buttonText={t('navbar.news')}
          menuOptions={[
            { text: t('navbar.newsMenu.alpha'), to: ROUTES.NEWS, icon: <NewsIcon width='16px' height='16px' /> },
            { text: t('navbar.newsMenu.events'), to: ROUTES.EVENTS, icon: <Calendar width='16px' height='16px' /> }
          ]}
        />
        <Link to={ROUTES.MARKETPLACE}>
          <Button
            height="48px"
            px='18px'
            py='15.5px'
            borderRadius='6px'
            color='#fff'
            fontSize='16px'
            fontWeight='400'
            bg='#2B2640'
            _hover={{ bg: '#0C0324', fontWeight: 'bold' }}
            _active={{ bg: '#0C0324', fontWeight: 'bold' }}
            marginRight={'28px'}
          >
            {t('navbar.store')}
          </Button>
        </Link>
        <Link to={ROUTES.ABOUT}>
          <Button
            height="48px"
            px='18px'
            py='15.5px'
            borderRadius='6px'
            color='#fff'
            fontSize='16px'
            fontWeight='400'
            bg='#2B2640'
            _hover={{ bg: '#0C0324', fontWeight: 'bold' }}
            _active={{ bg: '#0C0324', fontWeight: 'bold' }}
            marginRight={'6px'}
          >
            {t('navbar.about_ola')}
          </Button>
        </Link>
        <NavMenu
          buttonText={i18n.language.toLocaleUpperCase()}
          style={{ marginLeft: '50px' }}
          menuOptions={[
            { text: 'Español', onClick: () => changeLang('es') },
            { text: 'English', onClick: () => changeLang('en') },
            { text: 'Português', onClick: () => changeLang('pt') },
          ]}
        />
        {
          loggedIn ? (
            <Flex alignItems='center'>
              <MyAccountNav />
              <Flex mx='5' bgColor='gray' w='1px' h='38px'></Flex>
              <Notifications />
            </Flex>
          ) : (
            <Button
              variant="filledGradient"
              color="white"
              w="112px"
              fontSize={'18px'}
              type="button"
              top='0'
              _hover={{ transform: 'scale(0.98)', bg: '#4E10FF' }}
              _active={{ transform: 'scale(0.98)', bg: '#FF00F6' }}
              onClick={() => openOnboarding()}
            >
              {t('navbar.access')}
            </Button>
          )
        }
      </Flex>
    </Center>
  );
};

export default Nav;
